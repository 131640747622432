/* ==========================================================================
   Syntax highlighting and formatting
   ========================================================================== */

pre.highlight {
  padding: 1em;
}

/*
   Pygments.rb and Rouge
   ========================================================================== */

.linenos,
.code {
	padding: 0;
	border-top: 0 solid transparent;
	border-bottom: 0 solid transparent;
}
.linenodiv {
  @include font-size(16);
}

.highlight {
	overflow-x: auto;
	@include font-size(16);
	border: 1px solid darken($body-color, 5);
	border-radius: $border-radius;
	pre {
		position: relative;
		margin: 0;
		padding: 1em;
	}
	&:hover {
		border: 1px solid $form-border-color-hover;
	}
}

.highlighttable {
	tr:hover>td,
	tr:hover>th {
		background: transparent
	}
}

.hll { background-color: #ffffcc }

.err { color: #a61717; background-color: #e3d2d2 }                   // Error

.k { color: #000000; font-weight: bold }                             // Keyword

.o { color: #000000; font-weight: bold }                             // Operator

.c { color: #999988; font-style: italic }                            // Comment
.cm { color: #999988; font-style: italic }                           // Comment.Multiline
.cp { color: #999999; font-weight: bold; font-style: italic }        // Comment.Preproc
.c1 { color: #999988; font-style: italic }                           // Comment.Single
.cs { color: #999999; font-weight: bold; font-style: italic }        // Comment.Special


.gd { color: #000000; background-color: #ffdddd }                    // Generic.Deleted
.ge { color: #000000; font-style: italic }                           // Generic.Emph
.gr { color: #aa0000 }                                               // Generic.Error
.gh { color: #999999 }                                               // Generic.Heading
.gi { color: #000000; background-color: #ddffdd }                    // Generic.Inserted
.go { color: #888888 }                                               // Generic.Output
.gp { color: #555555 }                                               // Generic.Prompt
.gs { font-weight: bold }                                            // Generic.Strong
.gu { color: #aaaaaa }                                               // Generic.Subheading
.gt { color: #aa0000 }                                               // Generic.Traceback

.kc { color: #000000; font-weight: bold }                            // Keyword.Constant
.kd { color: #000000; font-weight: bold }                            // Keyword.Declaration
.kn { color: #000000; font-weight: bold }                            // Keyword.Namespace
.kp { color: #000000; font-weight: bold }                            // Keyword.Pseudo
.kr { color: #000000; font-weight: bold }                            // Keyword.Reserved
.kt { color: #445588; font-weight: bold }                            // Keyword.Type

.m { color: #009999 }                                                // Literal.Number
.mf { color: #009999 }                                               // Literal.Number.Float
.mh { color: #009999 }                                               // Literal.Number.Hex
.mi { color: #009999 }                                               // Literal.Number.Integer
.mo { color: #009999 }                                               // Literal.Number.Oct
.il { color: #009999 }                                               // Literal.Number.Integer.Long
.s { color: #d01040 }                                                // Literal.String
.sb { color: #d01040 }                                               // Literal.String.Backtick
.sc { color: #d01040 }                                               // Literal.String.Char
.sd { color: #d01040 }                                               // Literal.String.Doc
.s2 { color: #d01040 }                                               // Literal.String.Double
.se { color: #d01040 }                                               // Literal.String.Escape
.sh { color: #d01040 }                                               // Literal.String.Heredoc
.si { color: #d01040 }                                               // Literal.String.Interpol
.sx { color: #d01040 }                                               // Literal.String.Other
.sr { color: #009926 }                                               // Literal.String.Regex
.s1 { color: #d01040 }                                               // Literal.String.Single
.ss { color: #990073 }                                               // Literal.String.Symbol

.na { color: #008080 } // Name.Attribute
.nb { color: #0086B3 } // Name.Builtin
.nc { color: #445588; font-weight: bold } // Name.Class
.no { color: #008080 } // Name.Constant
.nd { color: #3c5d5d; font-weight: bold } // Name.Decorator
.ni { color: #800080 } // Name.Entity
.ne { color: #990000; font-weight: bold } // Name.Exception
.nf { color: #990000; font-weight: bold } // Name.Function
.nl { color: #990000; font-weight: bold } // Name.Label
.nn { color: #555555 } // Name.Namespace
.nt { color: #000080 } // Name.Tag
.bp { color: #999999 } // Name.Builtin.Pseudo
.nv { color: #008080 } // Name.Variable
.vc { color: #008080 } // Name.Variable.Class
.vg { color: #008080 } // Name.Variable.Global
.vi { color: #008080 } // Name.Variable.Instance

.ow { color: #000000; font-weight: bold } // Operator.Word

.w { color: #bbbbbb } // Text.Whitespace

/*
   GitHub Gists
   ========================================================================== */

//Remove extra padding
.gist table {
  margin-top:0px;

}

//Remove hover effect
.gist tbody
{
  tr:hover > td, tr:hover > th {
    background-color:transparent;
  };
  background-color:white; //Background color white
}
