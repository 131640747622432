/* ==========================================================================
   Notices
   ========================================================================== */

/*
   Default notice .notice
   ========================================================================== */

@mixin notice($notice-color) {
	position: relative;
	padding: 1.5em;
	font-family: $alt-font;
	@include font-size(14,39);
	color: $white;
	background-color: $notice-color;
	border-radius: $border-radius;
	a {
		color: $white;
		border-bottom: 1px dotted $white;
	}
}
.notice {
	@include notice($primary-color);
}

/*
   Inverse notice .notice-inverse
   ========================================================================== */

.notice-inverse {
	@include notice($white);
	color: $text-color;
	a {
		color: $text-color;
	}
}

/*
   Info notice .notice-info
   ========================================================================== */

.notice-info {
	@include notice($info-color);
}

/*
   Warning notice .notice-warning
   ========================================================================== */

.notice-warning {
	@include notice($warning-color);
}

/*
   Success notice .notice-success
   ========================================================================== */

.notice-success {
	@include notice($success-color);
}

/*
   Danger notice .notice-danger
   ========================================================================== */

.notice-danger {
	@include notice($danger-color);
}

/*
   Browser upgrade notice
   ========================================================================== */

.upgrade {
  text-align: center;
  a {
    text-decoration: none;
  }
}
