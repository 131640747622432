/* ==========================================================================
   Animations
   ========================================================================== */

/*
   Wiggle animation
   ========================================================================== */

@include keyframes(wiggle) {
	25%, 50%, 75%, 100% { @include transform-origin(top center); }
	25% { @include transform(rotate(8deg)); }
	50% { @include transform(rotate(-4deg)); }
	75% { @include transform(rotate(2deg)); }
	100% { @include transform(rotate(0deg)); }
}

/*
   Pop animation
   ========================================================================== */

@include keyframes(pop) {
	50% { @include transform(scale(1.1)); }
	100% { @include transform(scale(1)); }
}

/*
   Hang animation
   ========================================================================== */

@include keyframes(hang) {
	50% { @include transform(translateY(-3px)); }
	100% { @include transform(translateY(-6px)); }
}
.hang {
	display: inline-block;
	@include animation-name(hang);
	@include animation-duration(0.5s);
	@include animation-timing-function(linear);
	@include animation-iteration-count(infinite);
	@include animation-direction(alternate);
}

/*
	Carousel
	========================================================================= */

	$em-base: 20px !default;

	@function strip-units($value) {
	  @return ($value / ($value * 0 + 1));
	}
	@function em($pxval, $base: $em-base) {
	  @if not unitless($pxval) {
		$pxval: strip-units($pxval);
	  }
	  @if not unitless($base) {
		$base: strip-units($base);
	  }
	  @return ($pxval / $base) * 1em;
	}
	
	// Variables
	$numberOfImgs: 4;
	$imgSpacing: em(10px);
	$imgWidth: 100%;/*em(floor(640px/2));*/
	$imgHeight: auto;/*em(floor(543px/2));*/
	$fbHeaderHeight: em(57px);
	
	.PagesSlider {
	position: relative;
	width: $imgWidth;
	height: $imgHeight;
	}
	
	.PagesSlider-slides {
	position: relative;
	display: flex;
	  padding-left: $imgSpacing / 2;
	}
	
	.PagesSlider-slide {
	position: absolute;
	padding-left: $imgSpacing;

	width: $imgWidth;
	height: $imgHeight;
	  @for $i from 1 through $numberOfImgs {
		&:nth-child(#{$i}) {
		  animation: PagesSlider-animation 20s (-20s / $numberOfImgs * $i) ease-in-out infinite;
		}
	  }
	}
	
	@keyframes PagesSlider-animation {
	$initialIndex: 3;
	$index: $initialIndex;
	
	@while $index - $initialIndex <= $numberOfImgs {
	$startTime: 100% * ($index - $initialIndex) / $numberOfImgs;
	$timeToWait: 80% / $numberOfImgs;
	#{$startTime},
	#{min($startTime + $timeToWait, 100%)} {
	transform: translateX((100% * $numberOfImgs) - 100% * $index);
	}
	$index: $index + 1;
	}
	}


/*
	Testimonial Carousel
	========================================================================= */
.testimonial-carousel {
    max-width: 52em;
    /*margin: 0 auto;*/
    overflow: hidden;
    position: relative;
}

.testimonial-carousel-inner {
    display: flex;
    transition: transform 0.5s ease-in-out;
}

.testimonial-carousel-item {
    flex: 0 0 100%;
    /*text-align: center;*/
    /*padding: 20px;*/
}

.testimonial-carousel-item img {
    max-width: 100px;
}