/* ==========================================================================
   SCSS Variables
   ========================================================================== */

/*
   Typography variables
   ========================================================================== */

$base-font                   	: "Open Sans",sans-serif;
$heading-font                	: "Arial","Segoe UI",Arial,sans-serif;
$caption-font                	: $base-font;
$code-font                   	: monospace;
$alt-font                    	: $heading-font;

$doc-font-size               	: 18;
$doc-line-height             	: 22;

$border-radius               	: 0px;

/*
   Color variables
   ========================================================================== */

$white                        : #fff;
$black                        : #000;

$body-color                  	: #ebebeb;

$text-color                  	: $black;
$caption-color                : mix($white, $text-color, 25%);

$base-color                  	: #343434;
$comp-color                  	: complement($base-color);
$border-color                	: #ddd;
$link-color                  	: #222;

$primary-color               	: #116297;
$success-color               	: #2ecc71;
$warning-color               	: #f1c40f;
$danger-color                	: #e74c3c;
$info-color                  	: #3498db;

$table-border-color          	: $border-color;
$table-border                	: 1px solid $table-border-color;
$table-background            	: $body-color;
$table-header-color          	: lighten($table-background, 10);
$table-hover-color           	: darken($table-background, 2);
$table-stripe-color          	: darken($table-background, 4);
$table-stripe-color-hover    	: darken($table-stripe-color, 5);

$facebook-color					      : #3b5998;
$flickr-color					        : #ff0084;
$foursquare-color				      : #0cbadf;
$google-plus-color				    : #dd4b39;
$instagram-color				      : #4e433c;
$linkedin-color					      : #4875b4;
$pinterest-color				      : #cb2027;
$rss-color						        : #fa9b39;
$tumblr-color					        : #2c4762;
$twitter-color					      : #55acee;
$vimeo-color					        : #1ab7ea;
$youtube-color		            : #ff3333;


/*
   Form variables
   ========================================================================== */

$form-border-color           	: $border-color;
$form-border-color-hover     	: darken($border-color, 10);
$form-border-color-focus     	: $primary-color;
$form-border-radius          	: $border-radius;
$form-box-shadow             	: inset 0 1px 3px hsla(0, 0%, 0%, 0.06);
$form-box-shadow-focus       	: $form-box-shadow, 0 0 5px rgba(darken($form-border-color-focus, 5), 0.7);
$form-font-family            	: $base-font;
$form-font-size              	: $doc-font-size;


/*
   Sliding menu navigation variables
   ========================================================================== */

$sliding-menu-border-color	 	 : $primary-color;
$sliding-menu-background 		   : $primary-color;
$sliding-menu-color 	 		     : $white;
$sliding-menu-background-hover : $primary-color;
$sliding-menu-color-hover	 	   : $white;
$sliding-menu-border   			   : 1px solid $sliding-menu-border-color;
$button-size		         	     : 30px;
$transition						         : 0.3s; // increase this to see the transformations in slow-motion
$sliding-menu-animation        : all 500ms cubic-bezier(.86,.01,.77,.78);

/*
   Badge variables
   ========================================================================== */

$badge-background              : $primary-color;
$badge-dark-color              : $black;
$badge-danger-color            : $danger-color;
$badge-info-color              : $info-color;
$badge-warning-color           : $warning-color;
$badge-success-color           : $success-color;
$badge-font-color              : $white;


/*
   Masthead variables
   ========================================================================== */

$masthead-height: $button-size * 2;
