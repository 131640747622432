.row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
  }
  
  .column {
    display: flex;
    flex-direction: column;
    flex-basis: 100%;
  }

@media screen and (min-width: 1200px) {
  .column {
    flex: 1;
  }
}