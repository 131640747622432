/* ==========================================================================
   Badges
   ========================================================================== */

.badge {
	display: inline-block;
	background: $badge-background;
	border-radius: 2em;
	color: $badge-font-color;
	font-family: $alt-font;
	@include font-size(12,no);
	font-weight: 600;
	line-height: 1;
	padding: .25em 1em;
	text-align: center;

	&.inverse {
		background: $white;
		color: $text-color;
	}

	&.info {
		background: $badge-info-color;
		color: $white;
	}

	&.danger {
		background: $badge-danger-color;
		color: $white;
	}

	&.warning {
		background: $badge-warning-color;
		color: darken($badge-warning-color, 60);
	}

	&.success {
		background: $badge-success-color;
		color: darken($badge-success-color, 60);
	}
}
