/* ==========================================================================
   Footnotes
   ========================================================================== */

.footnotes {
  font-family: $alt-font;
  p, li {
    @include font-size(12,no);
  }
  &:before {
    content: 'Footnotes:';
    font-weight: 700;
  }
}
