/* ==========================================================================
   Visual bullets (image + text)
   ========================================================================== */

.bullets {
	$icon-bullet-size: $column;
	overflow: auto;

	// change ".three-col-bullet" class to this for two bullet layout
	.two-col-bullet {
		@include media($large) {
			@include span-columns(6);
			@include omega(2n);
		}
	}
	.three-col-bullet {
		@include media($large) {
			@include span-columns(4);
			@include omega(3n);
		}
	}
	// change ".three-col-bullet" class to this for four bullet layout
	.four-col-bullet {
		@include media($large) {
			@include span-columns(3);
			@include omega(4n);
		}
	}

	.bullet-icon {
		float: left;
		background: $base-color;
		padding: $icon-bullet-size /4;
		border-radius: 50%;
		width: $icon-bullet-size * 1.25;
		height: $icon-bullet-size * 1.25;
	}
	.bullet-content {
		margin-left: $icon-bullet-size * 1.4;
		margin-bottom: 2em;
	}
	h2 {
		margin-top: 0;
		@include font-size(20,no);
		display: inline-block;
	}
	p {
		@include font-size(14);
	}
}
