/* ==========================================================================
   Watermarks
   ========================================================================== */

         
.watermark-consult {
   background: url(/images/CONSULT-watermark.svg);
   background-size: cover;
   background-attachment: fixed;
   color: #ffffff;
   }
         
.watermark-solve {
   background: url(/images/SOLVE-watermark.svg);
   background-size: cover;
   background-attachment: fixed;
   color: #ffffff;
   }
         
.watermark-implement {
   background: url(/images/IMPLEMENT-watermark.svg);
   background-size: cover;
   background-attachment: fixed;
   color: #ffffff;
   }