/* ==========================================================================
   OFF CANVAS SLIDING MENU
   Based on code by Diego Eis
   http://tableless.com.br/fazendo-um-slide-menu-mobile-sem-plugin/
   ========================================================================== */

/* Slider */

.slide {
    transform: translateX(-100%);
    @include media($medium) {
      transform: translateX(em(-600));
    }
    @include media($large) {
      transform: translateX(em(-900));
    }
  }

/*
   Sliding menu button
   ========================================================================== */

.sliding-menu-button {
  position: fixed;
  transform: translateZ(0);
  backface-visibility: hidden; /* fix scroll jank */
  top: $gutter;
  right: $gutter;
  display: block;
  width: $button-size * 2;
  height: $button-size * 2;
  background: $primary-color;
  outline: 0;
  padding: 0;
  border: 2.5px solid transparent;
  cursor: pointer;
  z-index: 20;
  box-sizing: border-box;
  &:hover {
    background: $primary-color;
  }
  transition: $sliding-menu-animation;
  &.slide {
    background: $primary-color;
    transform: translateX(0); /* don't slide close button on small screens only */
    @include media($medium) {
      transform: translateX(em(-600)); /* reset slide position */
    }
    @include media($large) {
      transform: translateX(em(-900)); /* reset slide position */
    }
  }
}

/*
   Sliding content
   ========================================================================== */

.sliding-menu-content {
  position: fixed;
  top: 0;
  right: 0;
  padding: em(22) 0;
  visibility: hidden;
  backface-visibility: hidden;
  text-align: left;
  @include size(100% 100%);
  @include media($small) {
    width: em(600);
  }
  @include media($large) {
    width: em(900);
  }
  transform: translateX(100%);
  transition: $sliding-menu-animation;
  background: $sliding-menu-background;
  z-index: 20;
  overflow-y: auto;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;
  &.is-visible {
    visibility: visible;
    transform: translateX(0);
  }
  h5 {
    margin: 0 20% 0 10%;
    @include media($large) {
      margin-right: 20%;
    }
    color: $white;
    @include font-size(14);
    @include media($small) {
      @include font-size(16);
    }
    span {
      display: block;
      @include font-size(32,no,1);
      @include media($small) {
        @include font-size(48,no,1);
      }
      font-family: $base-font;
      text-transform: uppercase;
      font-weight: 400;
    }
  }
  ul {
    margin: 0 10%;
    @include media($large) {
      margin-right: 20%;
    }
  }
  ul,
  li {
    list-style: none;
  }
  li {
    display: block;
    position: relative;
    padding-bottom: 1em;
    min-height: 70px;
    @include media($small) {
      min-height: 100px;
    }
  }
  .menu-item {
    a {
      display: block;
      color: $white;
      text-decoration: none;
    }
    .teaser {
      position: absolute;
      left: 0;
      margin-bottom: 0;
      width: 50px;
      border: 2px solid $white;
      margin-bottom: ($gutter / 2);
      opacity: 0.6;
      @include media($small) {
        width: 150px;
      }
    }
    a:hover .teaser {
      opacity: 1;
    }
    .title {
      display: block;
      margin-left: 60px;
      font-family: $alt-font;
      @include font-size(24,no);
      font-weight: 700;
      @include media($small) {
        margin-left: 170px;
        @include font-size(32,no);
      }
    }
    .excerpt {
      margin-top: 0;
      margin-left: 60px;
      @include font-size(14,no);
      @include media($small) {
        margin-left: 170px;
        @include font-size(16,no);
      }
    }
  }
  .sub-menu-item > li a {
    display: block;
    color: $white;
    font-style: italic;
  }
  .menu-item .home a {
    @include font-size(32);
  }
}

/* Dim content when off canvas nav slides in */

.menu-screen {
  @include position(fixed, 0 0 0 0);
  visibility: hidden;
  z-index: 4;
  &.is-visible {
    visibility: visible;
    &:hover {
      cursor: pointer;
    }
  }
}
